import "./styles.scss"

import React from "react"

import Button from "components/Button"
import LazyFadeImage from "components/LazyFadeImage"

const Grid = () => {
  return (
    <section className="clients-grid">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/clients/clients-grid-image-1.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Apartamenty premium w miejscowościach turystycznych</h2>
            <p>
              Marzysz o apartamencie z widokiem na jezioro, morze lub
              zapierające dech w piersiach góry? Bez względu na to jaki kierunek
              wybierzesz jesteśmy po to, aby Ci pomóc. Dołożymy wszelkich
              starań, aby znaleźć dla Ciebie nieruchomość, w której poczujesz
              się jak w domu. Ciesz się chwilami z bliskimi lub czerp zyski z
              najmu krótkoterminowego.
            </p>
            <Button className="button--bordered" to="/portfel-nieruchomosci/">
              więcej
            </Button>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Usługi wykończenia i zarządzania apartamentami</h2>
            <p>
              Odbierz w pełni wykończony i wyposażony apartament w stylu, który
              pokochasz! Zdecyduj, czy nieruchomość będzie służyć Tobie i Twoim
              bliskim czy również stanie się źródłem dodatkowego dochodu.
            </p>
            <Button
              className="button--bordered"
              to="/dla-klientow/zarzadzanie-apartamentem-wykonczenie-wnetrz/"
            >
              więcej
            </Button>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/clients/clients-grid-image-2.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/clients/clients-grid-image-4.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Zwrot podatku VAT</h2>
            <p>
              Współpracujemy z zespołem ekspertów ds. prawa podatkowego. Doradca
              zarekomenduje najbardziej opłacalną dla Ciebie formę zakupu
              nieruchomości. Przeprowadzi przez proces uzyskania zwrotu VAT i
              skorzystania z ulg podatkowych.
            </p>
            <Button
              className="button--bordered"
              to="/dla-klientow/doradztwo-podatkowe/"
            >
              więcej
            </Button>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Finasowanie</h2>
            <p>
              Skorzystaj z pomocy doświadczonego Eksperta Finansowego, który
              porówna oferty finansowania aż kilkunastu banków i przeprowadzi
              Cię przez wszystkie formalności. Oferujemy wsparcie w uzyskaniu
              finansowania zarówno na lokale mieszkalne, jak i lokale użytkowe.
            </p>
            <Button
              className="button--bordered"
              to="/dla-klientow/finansowanie/"
            >
              więcej
            </Button>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/clients/clients-grid-image-5.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
