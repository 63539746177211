import "./styles.scss"

import React from "react"

const Hero = () => {
  return (
    <section className="clients-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Odnajdź wymarzoną przestrzeń</h2>
            <p>
              Szanujemy Twój czas i Twoje pieniądze. Oprócz szerokiej gamy
              apartamentów premium, przygotowaliśmy również bogatą ofertę usług
              dodatkowych. Za pośrednictwem naszych partnerów, możesz nie tylko
              odebrać wykończony i w pełni wyposażony apartament, ale również
              powierzyć go w zarządzanie profesjonalistom. A może potrzebujesz
              wsparcia w pozyskania finansowania bankowego lub chciałbyś
              odzyskać VAT? Nasi partnerzy są tutaj dla Ciebie.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <div
              className="clients-hero__image"
              style={{
                backgroundImage: `url('${
                  require("assets/images/clients/clients-hero-image.jpg")
                    .default
                }')`,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
