import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Baner = () => {
  return (
    <section className="clients-baner">
      <div className="container-fluid">
        <h2>Znajdź inwestycję dopasowaną do Ciebie</h2>
        <Button to="/wyszukiwarka/">Zobacz więcej</Button>
      </div>
    </section>
  )
}

export default Baner
