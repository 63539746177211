import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactGlobal from "components/ContactGlobal"

import { Hero, Icons, Grid, Baner } from "page_components/clients"

const Developers = () => {
  const title = "Dla klientów"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Oferta produktowa Marshall Real Estate została stworzona tak, by każdy mógł odnaleźć swoją wymarzoną przestrzeń. Znajdź inwestycje dopasowaną do Ciebie!",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Hero />
      <Icons />
      <Grid />
      <Baner />
      <ContactGlobal />
    </Layout>
  )
}

export default Developers
