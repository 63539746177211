import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Icons = () => {
  const data = [
    {
      title: "wyboru apartamentu",
      icon: require("assets/images/clients/clients-icons-icon-1.svg").default,
    },
    {
      title: "aranżacji wnętrz",
      icon: require("assets/images/clients/clients-icons-icon-2.svg").default,
    },
    {
      title: "profesjonalnej usługi najmu",
      icon: require("assets/images/clients/clients-icons-icon-3.svg").default,
    },
    {
      title: "pozyskiwania finansowania bankowego",
      icon: require("assets/images/clients/clients-icons-icon-4.svg").default,
    },
    {
      title: "doradztwa podatkowego",
      icon: require("assets/images/clients/clients-icons-icon-5.svg").default,
    },
  ]

  return (
    <section className="clients-icons">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-lg" key={index}>
              <div className="clients-icons__item">
                <div className="clients-icons__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.title}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Icons
